import React, { useState, useEffect, ChangeEvent } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_STOCKS, GET_PRODUCTS, SEARCH_STOCKS } from "../graphql/queries";
import PheonixTable from "../components/PheonixTable";
import {
  CssBaseline,
  Box,
  Container,
  useTheme,
  Drawer,
  CardContent,
  IconButton,
  Card,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import PheonixSearchComponent from "../components/PheonixSearchComponent";
import PheonixButton from "../components/PheonixButton";
import styled from "styled-components";
import StockModal from "../components/StockModal";
import { ADD_STOCK, EDIT_STOCK, DELETE_STOCK } from "../graphql/mutations";
import PheonixSnackbar from "../components/PheonixSnackbar";
import PheonixSnackbarProps from "../interfaces/PheonixSnackbarProps";
import { ERROR_MESSAGES } from "../constants";
import StockProps from "../interfaces/StockProps";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ProductProps from "../interfaces/ProductProps";
import DeleteModal from "../components/DeleteModal";
import PheonixPaper from "../components/PheonixPaper";
const StyledDiv = styled.div`
  padding: 4px 42px 4px 50px;
  @media (max-width: 707px) {
    padding: 4px 42px 4px 20px;
  }
`;
const StockList: React.FC = ({}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState<StockProps[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [stockFormData, setStockFormData] = useState({
    "Product Name": "",
    "Batch Number": "",
    MRP: "",
    PTR: "",
    "Expiry Date": "",
    Quantity: "",
  });
  const [filteredStocksData, setFilteredStocksData] = useState<StockProps[]>(
    []
  );
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameerrorMessage, setNameErrorMessage] = useState("");
  const [addresserrorMessage, setAddressErrorMessage] = useState("");
  const [phonnoerrorMessage, setPhonenoErrorMessage] = useState("");
  const [email, setemail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [customerIdToDelete, setCustomerIdToDelete] = useState<string>("");
  const [iscustomerButtonDisabled, setIscustomerButtonDisabled] =
    useState(true);
  const [addstockMutation] = useMutation(ADD_STOCK);
  const [deleteStockMutation] = useMutation(DELETE_STOCK);
  const [editstockMutation] = useMutation(EDIT_STOCK);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const limit = rowsPerPage;
  const {
    loading: stockloading,
    error: stockError,
    data: stockData,
    refetch: refetchStock,
  } = useQuery(GET_STOCKS, {
    variables: {
      limit,
      offset: (currentPage - 1) * rowsPerPage,
    },
    onCompleted: (data) => {
      setTableData(data.getAllStocks.stocks);
      setTableCount(data.getAllStocks.totalCount);
      setTotalPages(Math.ceil(data.getAllStocks.totalCount / limit));
    },
  });
  useEffect(() => {
    if (!stockloading && !stockError && stockData) {
      setTableData(stockData.getAllStocks.stocks);
      setTableCount(stockData.getAllStocks.totalCount);
      setTotalPages(Math.ceil(stockData.getAllStocks.totalCount / limit));
    }
    getProductData();
  }, [stockData, stockloading, stockError]);

  useEffect(() => {
    refetchStock();
  }, []);

  const updateFormEmptyState = () => {
    const isEmpty = Object.values(stockFormData).some(
      (value) => value.trim() === ""
    );
    setIsFormEmpty(isEmpty);
  };
  const [inputValue, setInputValue] = useState("");
  const [products, setProducts] = useState<ProductProps[]>([]);
  const {
    loading: productLoading,
    error: productError,
    data: productData,
    refetch: getProductData,
  } = useQuery(GET_PRODUCTS, {
    variables: {
      limit: 10,
      offset: 0,
    },
  });
  const [productNames, setProductNames] = useState<string[]>([]);
  useEffect(() => {
    if (!productLoading && !productError && productData) {
      const products: ProductProps[] = productData.getAllProducts.products;
      const names: string[] = products.map(
        (product: ProductProps) => product.product_name_packing
      );
      setProductNames(names);
    }
  }, [productData, productLoading, productError]);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (page > currentPage) {
      setCurrentPage(currentPage + 1);
    } else if (page < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleEmailFocus = () => {
    setErrorMessage("");
  };
  const handleEmailBlur = () => {
    const emailRegex = ERROR_MESSAGES.EMAIL_REG;
    if (!emailRegex.test(email)) {
      setErrorMessage(ERROR_MESSAGES.VALID_EMAIL);
    } else {
      setErrorMessage("");
    }
  };
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    const filteredData = tableData.filter((order) =>
      Object.values(order).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredStocksData(filteredData);
  };
  const handleOpenModal = () => {
    setIsEditModal(false);
    setStockFormData({
      "Product Name": "",
      "Batch Number": "",
      MRP: "",
      PTR: "",
      "Expiry Date": "",
      Quantity: "",
    });
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setStockFormData({
      "Product Name": "",
      "Batch Number": "",
      MRP: "",
      PTR: "",
      "Expiry Date": "",
      Quantity: "",
    });
    setErrorMessage("");
    setAddressErrorMessage("");
    setNameErrorMessage("");
    setIsModalOpen(false);
  };
  const fields = [
    { label: "Product Name", type: "textarea" },
    { label: "Batch Number", type: "text" },
    { label: "MRP", type: "number" },
    { label: "Expiry Date", type: "text" },
    { label: "Quantity", type: "text" },
  ];
  const [editstockId, setEditStockId] = useState<string>("");
  const handleEditClick = (stockId: string) => {
    const stock = tableData.find((stock) => stock.id === stockId);
    if (stock) {
      setEditStockId(stockId);
      setStockFormData({
        "Product Name": stock.product_name,
        "Batch Number": stock.batch_no,
        MRP: stock.mrp,
        PTR: stock.ptramt,
        "Expiry Date": stock.expiry_date,
        Quantity: stock.quantity,
      });
      setIsEditModal(true);
      setIsModalOpen(true);
    }
  };

  const handleConfirm = async () => {
    try {
      let result;
      if (isEditModal) {
        const updateStock = tableData.find((stock) => stock.id === editstockId);
        if (
          updateStock &&
          (updateStock.product_name !== stockFormData["Product Name"] ||
            updateStock.batch_no !== stockFormData["Batch Number"] ||
            updateStock.mrp !== stockFormData["MRP"] ||
            updateStock.expiry_date !== stockFormData["Expiry Date"] ||
            updateStock.quantity !== stockFormData["Quantity"])
        ) {
          result = await editstockMutation({
            variables: {
              id: editstockId,
              product_name: stockFormData["Product Name"],
              batch_no: stockFormData["Batch Number"],
              mrp: stockFormData["MRP"],
              expiry_date: stockFormData["Expiry Date"],
              quantity: stockFormData["Quantity"],
            },
          });
          setSnackbarOpen(true);
          const successMessage = isEditModal
            ? result.data.editStock.message
            : result.data.createStock.message;
          setSnackbarMessage(successMessage);
          refetchStock();
          handleCloseModal();
        } else {
          setSnackbarMessage(ERROR_MESSAGES.CUSTOMER_NOT_UPDATED);
          setSnackbarOpen(true);
          setIsEditModal(false);
          setIsModalOpen(false);
          handleCloseModal();
        }
      } else {
        result = await addstockMutation({
          variables: {
            product_name: stockFormData["Product Name"],
            batch_no: stockFormData["Batch Number"],
            mrp: stockFormData["MRP"],
            expiry_date: stockFormData["Expiry Date"],
            quantity: stockFormData["Quantity"],
          },
        });
        if (result.data.createStock.message) {
          refetchStock();
          setStockFormData({
            "Product Name": "",
            "Batch Number": "",
            MRP: "",
            PTR: "",
            "Expiry Date": "",
            Quantity: "",
          });
          setSnackbarOpen(true);
          const successMessage = isEditModal
            ? result.data.editStock.message
            : result.data.createStock.message;
          setSnackbarMessage(successMessage);
          handleCloseModal();
        }
      }
    } catch (error) {}
  };

  const handleDeleteClick = async (stockId: string) => {
    setCustomerIdToDelete(stockId);
    setOpenDeleteConfirmation(true);
  };
  const handleDeleteConfirmed = async () => {
    try {
      const result = await deleteStockMutation({
        variables: {
          id: customerIdToDelete,
        },
      });

      refetchStock();
      setSnackbarOpen(true);
      setSnackbarMessage(ERROR_MESSAGES.DELETE_SUCCESS_STOCK);
    } catch (error) {
      console.error(error);
    } finally {
      setOpenDeleteConfirmation(false);
    }
  };
  const columns = [
    { id: "serial_no", label: "S.No" },
    { id: "product_name", label: "Product Name" },
    { id: "batch_no", label: "Batch Number" },
    { id: "mrp", label: "MRP" },
    { id: "ptramt", label: "PTR" },
    { id: "expiry_date", label: "Expiry Date" },
    { id: "quantity", label: "Quantity" },
    {
      id: "actions",
      label: "Actions",
      align: "center",
      minWidth: 100,
      render: (row: StockProps) => {
        return (
          <div style={{ flexDirection: "row" }}>
            <IconButton
              onClick={() => handleEditClick(row.id)}
              style={{
                color: "#2E7D32",
                border: "none",
                fontSize: "16px",
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteClick(row.id)}
              style={{
                fontSize: "16px",
                color: "#D32F2F",
                border: "none",
                marginLeft: 0,
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ width: "95%" }}>
      <PheonixSnackbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        vertical="top"
        horizontal="center"
        contentProps={{ sx: { backgroundColor: "#1A504C" } }}
      />
      <StyledDiv>
        <PheonixPaper
          elevation={1}
          marginLeft={-20}
          marginRight={-30}
          height={100}
          marginTop={25}
          style={{
            border: 0,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            marginBottom: "25px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            width: isMobile ? "116%" : "104%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PheonixSearchComponent onChange={handleSearch} />
            <div style={{ marginRight: "30px" }}>
              <PheonixButton
                variant="contained"
                onClick={handleOpenModal}
                style={{
                  color: "white",
                  marginTop: "-8px",
                  backgroundColor: "#165D59",
                  fontSize: "16px",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);",
                }}
              >
                {ERROR_MESSAGES.STOCK_BTN}
              </PheonixButton>
            </div>
          </div>
        </PheonixPaper>
        <PheonixTable
          columns={columns}
          data={
            searchQuery && filteredStocksData ? filteredStocksData : tableData
          }
          totalPages={tableCount}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          handlePageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledDiv>
      {!productLoading && !productError && (
        <StockModal
          open={isModalOpen}
          onClose={handleCloseModal}
          title={isEditModal ? "Edit Stock" : ERROR_MESSAGES.STOCK_MDL_TITLE}
          fields={fields}
          onConfirm={handleConfirm}
          confirmButtonText={ERROR_MESSAGES.SAVE}
          formData={stockFormData}
          setFormData={setStockFormData}
          isEditModal={isEditModal}
          errorMessage={errorMessage}
          phonenoErrorMessage={phonnoerrorMessage}
          setErrorMessage={setErrorMessage}
          setPhonenoErrorMessage={setPhonenoErrorMessage}
          nameerrorMessage={nameerrorMessage}
          setNameErrorMessage={setNameErrorMessage}
          addresserrorMessage={addresserrorMessage}
          setAddressErrorMessage={setAddressErrorMessage}
          dropdowndata={productNames}
          inputvalue={inputValue}
          onChangedata={setInputValue}
          disabled={!iscustomerButtonDisabled}
          close={ERROR_MESSAGES.CLOSE}
          onBlur={handleEmailBlur}
          onFocus={handleEmailFocus}
          isFormEmpty={isFormEmpty}
          updateFormEmptyState={updateFormEmptyState}
        />
      )}
      <DeleteModal
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={handleDeleteConfirmed}
        entity={ERROR_MESSAGES.STOCK}
      />
    </div>
  );
};
export default StockList;
