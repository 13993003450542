import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { ERROR_MESSAGES } from "../../constants";

interface TopSellingCardProps {
  items: string[];
  backgroundColors: string[];
}

const TopSelling: React.FC<TopSellingCardProps> = ({
  items,
  backgroundColors,
}) => {
  return (
    <Card
      style={{
        width: "390px",
        height: "460px",
        marginTop: "15px",
        border: "1px solid #0000001F",
        boxShadow: "0 2px 4px rgba(0,0,0,0.7)",
        overflowY: "auto",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginLeft: "14px",
          marginTop: "3%",
          fontSize: "20px",
          fontFamily: "Inter",
          fontWeight: "600",
        }}
      >
        {ERROR_MESSAGES.TOP_TXT}
      </Typography>
      <CardContent>
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              marginBottom: "8px",
              padding: "10px",
              border: "1px solid #ccc",
              backgroundColor: backgroundColors[index],
              color: "#000000",
              fontSize: "16px",
              fontFamily: "Inter",
              fontWeight: "400",
              overflowY: "auto",
            }}
          >
            {item}
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default TopSelling;
