import React, { useState, useEffect, ChangeEvent } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SALES } from "../graphql/queries";
import PheonixTable from "../components/PheonixTable";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { pdfdata } from "../constants";
import {
  CssBaseline,
  Box,
  Container,
  useTheme,
  Drawer,
  CardContent,
  Card,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import PheonixSearchComponent from "../components/PheonixSearchComponent";
import PheonixButton from "../components/PheonixButton";
import styled from "styled-components";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import InvoiceModal from "../components/InvoiceModal";
import {
  ADD_CUSTOMER,
  DELETE_CUSTOMER,
  EDIT_CUSTOMER,
  DELETE_SALES,
  EDIT_SALES_INVOICE,
} from "../graphql/mutations";
import { RowData, TableColumn } from "../interfaces/PheonixInvoiceTableProps";
import PheonixSnackbar from "../components/PheonixSnackbar";
import PheonixSnackbarProps from "../interfaces/PheonixSnackbarProps";
import { ERROR_MESSAGES } from "../constants";
import CustomerProps from "../interfaces/CustomerProps";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModal from "../components/DeleteModal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

interface InvoiceSalesProps {
  status: boolean;
  searchQuery: string;
}
const StyledDiv = styled.div`
  padding: 4px 42px 4px 50px;
  @media (max-width: 707px) {
    padding: 4px 42px 4px 20px;
  }
`;
const InvoiceSales: React.FC<InvoiceSalesProps> = ({ status, searchQuery }) => {
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState<RowData[]>([]);
  const [editDetails, setEditDetails] = useState<RowData[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [customerFormData, setCustomerFormData] = useState({
    "Invoice No": "",
    Supplier: "",
    InvoiceDate: "",
  });
  const [filteredCustomersData, setFilteredCustomersData] = useState<RowData[]>(
    []
  );
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [value, setValue] = React.useState("1");
  const [nameerrorMessage, setNameErrorMessage] = useState("");
  const [addresserrorMessage, setAddressErrorMessage] = useState("");
  const [phonnoerrorMessage, setPhonenoErrorMessage] = useState("");
  const [editId, setEditId] = useState("");
  const [salesIdToDelete, setSalesIdToDelete] = useState<string>("");
  const [iscustomerButtonDisabled, setIscustomerButtonDisabled] =
    useState(true);
  const [addCustomerMutation] = useMutation(ADD_CUSTOMER);
  const [deleteCustomerMutation] = useMutation(DELETE_CUSTOMER);
  const [deleteSales] = useMutation(DELETE_SALES);
  const [editSales] = useMutation(EDIT_SALES_INVOICE);
  const [editcustomerMutation] = useMutation(EDIT_CUSTOMER);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const limit = rowsPerPage;
  const { loading, error, data, refetch } = useQuery(GET_SALES, {
    variables: {
      limit,
      offset: (currentPage - 1) * limit,
    },
    onCompleted: (data) => {
      setTableData(data.getAllSales.sales);
      setTableCount(data.getAllSales.totalCount);
      setTotalPages(Math.ceil(data.getAllSales.totalCount / limit));
    },
  });
  useEffect(() => {
    if (!loading && !error && data) {
      setTableData(data.getAllSales.sales);
      setTableCount(data.getAllSales.totalCount);
      setTotalPages(Math.ceil(data.getAllSales.totalCount / limit));
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (status) {
      refetch();
    }
  }, [status]);

  useEffect(() => {
    if (searchQuery) {
      handleSearch(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    refetch();
  }, []);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handleButtonClick = (editId: string) => {
    let sales: any = tableData.find((sales) => sales.id === editId);
    if (sales) {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      doc.setPage(1);
      doc.setFillColor("#EFEFEF");
      doc.rect(0, 0, doc.internal.pageSize.getWidth(), 20, "F");
      doc.setFontSize(18);
      doc.setFont("helvetica", "bold");
      doc.text("Invoice", 12, 15);
      doc.setFontSize(11);
      const rightMargin = 20;
      const invoiceText = `Invoice Number: `;
      doc.text(invoiceText, doc.internal.pageSize.getWidth() - 29, 30, {
        align: "right",
      });
      doc.setFont("helvetica", "normal");
      const invoiceNumberSize = 11;
      doc.text(
        `#${sales.invoice_no}`,
        doc.internal.pageSize.getWidth() +
          8 -
          doc.getStringUnitWidth(sales.invoice_no) * invoiceNumberSize -
          10,
        30,
        { align: "right" }
      );
      doc.setFont("helvetica", "bold");
      const invoiceDate = `Invoice Date:`;
      doc.text(invoiceDate, doc.internal.pageSize.getWidth() - 40, 35, {
        align: "right",
      });
      doc.setFont("helvetica", "normal");
      doc.text(
        sales.invoice_date,
        doc.internal.pageSize.getWidth() +
          8 -
          doc.getStringUnitWidth(sales.invoice_no) * invoiceNumberSize -
          10,
        35,
        { align: "right" }
      );
      doc.setFontSize(12);
      doc.setFont("helvetica", "bold");
      doc.text(`PHARMA `, 12, 30);
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
      const pharmaAddress = pdfdata.PHARMA_ADDRESS;
      doc.text(pharmaAddress, 10, 32);
      doc.setFont("helvetica", "bold");
      doc.text(
        `BANK DETAILS`,
        doc.internal.pageSize.getWidth() - rightMargin,
        60,
        { align: "right" }
      );
      doc.setFontSize(11);
      doc.setFont("helvetica", "normal");
      const bankDetails = pdfdata.BANK_DETAILS;
      doc.text(
        bankDetails,
        doc.internal.pageSize.getWidth() - rightMargin,
        62,
        { align: "right" }
      );
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      doc.text(`BILL TO`, 12, 60);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      doc.text(`${sales.customer_details.customer_name}`, 12, 65);
      const customerAddress = sales.customer_details.address
        .split(/,|\r?\n/)
        .map((item: any) => item.trim())
        .filter((item: any) => item !== "");
      let customerAddressY = 70;
      customerAddress.forEach((part: any) => {
        doc.text(part, 12, customerAddressY);
        customerAddressY += 5;
      });
      doc.text(`${sales.customer_details.email}`, 12, customerAddressY);
      doc.text(`${sales.customer_details.phone_no}`, 12, customerAddressY + 5);

      const bankDetailsLines = bankDetails.split(/\r?\n/);
      const bankDetailsHeight = bankDetailsLines.length * 5;
      const phoneNumberHeight = 5;
      const addressTotalHeight = customerAddress.length * 5;
      const maxTextHeight = Math.max(
        bankDetailsHeight,
        phoneNumberHeight,
        addressTotalHeight
      );

      let startY = 65 + maxTextHeight;

      let serialNumber = 1;
      const bodyData = sales.products.map((product: any) => [
        serialNumber++,
        product.product_name,
        product.batch_no,
        product.mrp,
        product.ptr,
        product.expiry_date,
        product.quantity,
        product.gst,
        product.amount,
      ]);
      const extraRows = [
        `Total Item`,
        sales.total_item,
        ``,
        ``,
        ``,
        `Total Quantity`,
        sales.total_quantity,
        `Total Amount`,
        sales.total_amount,
      ];
      bodyData.push(extraRows);
      let discountAmount = 0;
      let amount = parseFloat(sales.total_amount);
      let tax = parseFloat(sales.tax);
      let dis = parseFloat(sales.discount);
      if (!isNaN(amount) && !isNaN(tax)) {
        if (sales.discount && !isNaN(sales.discount)) {
          discountAmount = (amount + tax) * (dis / 100);
        }
      }
      if (sales.igst && sales.igst > 0) {
        bodyData.push([``, ``, ``, ``, ``, ``, ``, `IGST`, sales.igst]);
      } else {
        bodyData.push([``, ``, ``, ``, ``, ``, ``, `CGST`, sales.cgst]);
        bodyData.push([``, ``, ``, ``, ``, ``, ``, `SGST`, sales.sgst]);
      }
      if (sales.discount && sales.discount > 0) {
        bodyData.push([
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          ``,
          `Discount`,
          discountAmount.toFixed(2),
        ]);
      }
      bodyData.push([
        ``,
        ``,
        ``,
        ``,
        ``,
        ``,
        ``,
        `Net Amount`,
        sales.net_amount,
      ]);
      var customStyles = {
        fillColor: [255, 255, 255],

        columnStyles: {
          7: { align: "right" },
        },
        lineHeight: 50,
        cellPadding: 2,
        textColor: [0, 0, 0],
        fontSize: 10,
      };
      // @ts-ignore
      const table = doc.autoTable({
        startY,
        head: [
          [
            "S.No",
            "Product",
            "BatchNo",
            "MRP",
            "PTR",
            "Expiry Date",
            "Quantity",
            "GST",
            "Amount",
          ],
        ],
        body: bodyData,
        styles: customStyles,
        didDrawPage: (hookData: { cursor: { y: number } }) => {
          startY = hookData.cursor.y + 10;
        },
        didParseCell: function (table: any) {
          if (table.section === "head") {
            table.cell.styles.textColor = "#000000";
            table.cell.styles.fontSize = 11;
          }
        },
      });
      const numBodyRows = bodyData.length;
      const padding = 5;
      const tableHeight = startY + numBodyRows + padding;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(12);
      const text = `Total Invoice Amount INR ${sales.net_amount}`;
      const invoiceX = doc.internal.pageSize.getWidth() - 15;
      doc.text(text, invoiceX, tableHeight, { align: "right" });

      doc.save("invoice.pdf");
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (page > currentPage) {
      setCurrentPage(currentPage + 1);
    } else if (page < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  function hasMatchingProduct(updateProduct: any, rows: any): boolean {
    return rows.some(
      (row: any) =>
        row.ProductName === updateProduct.product_name &&
        row.Quantity === updateProduct.quantity
    );
  }

  const handleConfirm = async (
    customerName: { id: string; name: string }[],
    newNetAmount: number | null,
    cgst: number | null,
    tax: number | null,
    items: number | null,
    quantity: number | null,
    total: number | null,
    rows: RowData,
    igst: number | null,
    discount: number | null
  ) => {
    const products = rows.map((row: RowData) => ({
      product_name: row.ProductName || null,
      product_id: row.product_id || null,
      batch_no: row.BatchNumber || null,
      mrp: row.MRP || null,
      ptr: row.PTR || null,
      expiry_date: row.ExpiryDate || null,
      quantity: row.Quantity || null,
      gst: row.GST || null,
      amount: row.Amount || null,
    }));

    const name = Array.isArray(customerName)
      ? customerName.length > 0
        ? customerName[0].name
        : null
      : (customerName as unknown as { id: string; name: string }).name ?? null;
    const id = Array.isArray(customerName)
      ? customerName.length > 0
        ? customerName[0].id
        : null
      : (customerName as unknown as { id: string; name: string }).id ?? null;
    const updateSales = tableData.find((sales) => sales.id === editId);

    if (
      updateSales &&
      (updateSales.customer_id !== id ||
        updateSales.total_item !== items?.toString() ||
        updateSales.total_quantity !== quantity?.toString() ||
        updateSales.total_amount !== total?.toString() ||
        updateSales.net_amount !== newNetAmount?.toString() ||
        updateSales.tax !== tax?.toString() ||
        updateSales.discount !== discount ||
        updateSales.cgst !== cgst?.toString() ||
        updateSales.sgst !== cgst?.toString() ||
        updateSales.igst !== igst?.toString() ||
        !updateSales.products.every((product: any) =>
          hasMatchingProduct(product, rows)
        ))
    ) {
      let result;
      result = await editSales({
        variables: {
          id: editId,
          customer_name: name,
          customer_id: id,
          products: products,
          total_item: items?.toString(),
          total_quantity: quantity?.toString(),
          total_amount: total?.toString(),
          net_amount: newNetAmount?.toString(),
          tax: tax?.toString(),
          discount: discount?.toString(),
          cgst: cgst?.toString(),
          sgst: cgst?.toString(),
          igst: igst?.toString(),
        },
      });
      if (result.data && result.data.editSales) {
        const { message } = result.data.editSales;
        if (message) {
          setSnackbarOpen(true);
          refetch();
          setIsEditModal(false);
          setSnackbarMessage(message);
        } else {
          setSnackbarOpen(true);
          setIsEditModal(false);
          setSnackbarMessage(ERROR_MESSAGES.INVOICE_FAILURE);
        }
      } else {
        setSnackbarOpen(true);
        setIsEditModal(false);
        setSnackbarMessage(ERROR_MESSAGES.INVOICE_FAILURE);
      }
    } else {
      setSnackbarMessage(ERROR_MESSAGES.CUSTOMER_NOT_UPDATED);
      setSnackbarOpen(true);
      setIsEditModal(false);
      handleCloseModal();
    }
  };

  const updateFormEmptyState = () => {
    const isEmpty = Object.values(customerFormData).some(
      (value) => value.trim() === ""
    );
    setIsFormEmpty(isEmpty);
  };

  const handleSearch = (searchQuery: string) => {
    if (searchQuery === "") {
      setFilteredCustomersData(tableData);
    } else {
      const filteredData = tableData.filter((order) =>
        Object.values(order).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );

      setFilteredCustomersData(filteredData);
    }
  };
  const handleOpenModal = () => {
    setIsEditModal(false);
    setCustomerFormData({
      "Invoice No": "",
      Supplier: "",
      InvoiceDate: "",
    });
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setErrorMessage("");
    setAddressErrorMessage("");
    setNameErrorMessage("");
    setIsEditModal(false);
  };
  const [editCustomerId, setEditCustomerId] = useState<string>("");

  const handleEditClick = (editId: string) => {
    const sales: RowData | null =
      tableData.find((sale) => sale.id === editId) || null;

    if (sales) {
      setValue("1");
      setEditId(editId);

      setEditDetails([sales]);
      setIsEditModal(true);
      setIsModalOpen(true);
    }
  };

  const handleDeleteClick = async (salesId: string) => {
    setSalesIdToDelete(salesId);
    setOpenDeleteConfirmation(true);
  };
  const handleDeleteConfirmed = async () => {
    try {
      const result = await deleteSales({
        variables: {
          id: salesIdToDelete,
        },
      });

      refetch();
      setSnackbarOpen(true);
      setSnackbarMessage(ERROR_MESSAGES.SALES_DELETE);
    } catch (error) {
      setOpenDeleteConfirmation(false);
    } finally {
      setOpenDeleteConfirmation(false);
    }
  };
  const columns = [
    { id: "serial_no", label: "S.No" },
    { id: "invoice_no", label: "Invoice No" },
    { id: "sales_code", label: "Sales Code" },
    { id: "customer_name", label: "Customer " },
    { id: "invoice_date", label: "Invoice Date" },

    {
      id: "actions",
      label: "Actions",
      align: "center",
      render: (row: CustomerProps) => {
        return (
          <div style={{ flexDirection: "row", justifyContent: "center" }}>
            <IconButton
              onClick={() => handleEditClick(row.id)}
              style={{
                color: "#2E7D32",
                fontSize: "16px",
                outline: "none",
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteClick(row.id)}
              style={{
                fontSize: "16px",
                color: "#D32F2F",
                outline: "none",
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={() => handleButtonClick(row.id)}
              style={{
                fontSize: "16px",
                color: "#165D59",
                outline: "none",
              }}
            >
              <SaveAltIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ width: "95%", marginLeft: "3%", marginTop: "-1%" }}>
      <PheonixSnackbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "40px",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        vertical="top"
        horizontal="center"
        contentProps={{ sx: { backgroundColor: "#1A504C" } }}
      />

      <PheonixTable
        columns={columns}
        data={
          searchQuery && filteredCustomersData
            ? filteredCustomersData
            : tableData
        }
        totalPages={tableCount}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        handlePageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {!loading && !error && value === "1" && (
        <InvoiceModal
          open={isEditModal}
          onClose={handleCloseModal}
          title={ERROR_MESSAGES.INVOICE_SALES}
          onConfirm={handleConfirm}
          confirmButtonText={ERROR_MESSAGES.UPDATE_INVOICE}
          formData={editDetails}
          setFormData={setEditDetails}
          close={ERROR_MESSAGES.CLOSE}
          isEditModal={isEditModal}
          errorMessage={errorMessage}
          disabled={!iscustomerButtonDisabled}
          isFormEmpty={isFormEmpty}
          updateFormEmptyState={updateFormEmptyState}
          value={value}
        />
      )}

      <DeleteModal
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={handleDeleteConfirmed}
        entity={ERROR_MESSAGES.SALES_NAME}
      />
    </div>
  );
};
export default InvoiceSales;
