import React from "react";
import MuiButton, { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import PheonixLoginButtonProps from "../interfaces/PheonixLoginButtonProps";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const PheonixButton: React.FC<PheonixLoginButtonProps> = ({
  label,
  disabled,
  onClick,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const buttonStyle = isMobile
    ? { width: "250px", height: "42px" }
    : { width: "350px", height: "42px" };
  return (
    <MuiButton
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      style={{
        ...buttonStyle,
        backgroundColor: disabled ? "lightgray" : "#1A504C",
        color: disabled ? "darkgray" : "white",
        padding: "5px",
        borderRadius: "4px",
        border: "1px",
        boxSizing: "border-box",
        marginLeft: "0",
        marginTop: "5%",
      }}
      {...props}
    >
      {label}
    </MuiButton>
  );
};
export default PheonixButton;
