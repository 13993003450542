import React from "react";
import { Card, CardContent, Box, Typography } from "@mui/material";

interface StatCardProps {
  data: {
    count: number;
    name: string;
    image: string;
    backgroundColor?: string;
  }[];
}

const StatCard: React.FC<StatCardProps> = ({ data }) => {
  return (
    <>
      {data.map((card, index) => (
        <Card
          key={index}
          style={{
            justifyContent: "space-around",
            margin: "auto",
            width: "170px",
            height: "100px",
            backgroundColor: card.backgroundColor,
            padding: "0px 24px",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <img
                src={card.image}
                alt="Your Logo"
                style={{ marginBottom: "0%", width: "35px" }}
              />
              <div style={{ marginLeft: "10px" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "18px",
                    fontFamily: "Inter",
                    fontWeight: "600",
                  }}
                >
                  {card.count}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "16px",
                    fontFamily: "Inter",
                    fontWeight: "600",
                  }}
                >
                  {card.name}
                </Typography>
              </div>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default StatCard;
