import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Grid,
  TextareaAutosize,
  IconButton,
  Typography,
} from "@mui/material";
import SupplierModalProps from "../interfaces/SupplierModalProps";
import PheonixButton from "./PheonixButton";
import CloseIcon from "@mui/icons-material/Close";
import { ERROR_MESSAGES } from "../constants";

const SupplierModal = <T,>({
  open,
  onClose,
  title,
  fields,
  onConfirm,
  onKey,
  confirmButtonText,
  formData,
  setFormData,
  isEditModal,
  errorMessage,
  phonenoErrorMessage,
  setErrorMessage,
  setPhonenoErrorMessage,
  nameerrorMessage,
  addresserrorMessage,
  regerrorMessage,
  setNameErrorMessage,
  setRegErrorMessage,
  setAddressErrorMessage,
  onBlur,
  onFocus,
  disabled,
  emailError,
  close,
  fieldErrors1,
  setFieldErrors1,
  ...rest
}: SupplierModalProps<T>) => {
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  useEffect(() => {
    if (open) {
      setFieldErrors({});
      setIsButtonDisabled(!isEditModal);
    }
  }, [open, isEditModal]);
  useEffect(() => {
    validateForm();
  }, [formData]);
  const validateForm = () => {
    let hasErrors = false;
    let isAnyFieldEmpty = false;
    fields.forEach((field) => {
      const fieldValue = (formData as { [key: string]: string })[
        field.label
      ].trim();
      if (!fieldValue) {
        isAnyFieldEmpty = true;
      } else {
        validateField(field.label);
      }
    });
    setIsButtonDisabled(
      isAnyFieldEmpty ||
        (formData as { "Phone Number": string })["Phone Number"].length !== 10
    );
  };

  useEffect(() => {
    const hasErrors = Object.values(fieldErrors).some((error) => !!error);
    setIsConfirmDisabled(hasErrors);
  }, [fieldErrors]);
  const handleFieldChange = (fieldLabel: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [fieldLabel]: value }));
    setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));

    if (fieldLabel === "Phone Number") {
      setIsButtonDisabled(value.length !== 10);
    } else {
      validateForm();
    }
  };
  const handleFieldBlur = (fieldLabel: string) => {
    validateField(fieldLabel);
  };
  const validateField = (fieldLabel: string) => {
    const fieldValue = (formData as { [key: string]: string })[fieldLabel];
    switch (fieldLabel) {
      case "Supplier Name":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.SUPPLIER_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Registration No":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.REG_REQ,
          }));
        } else if (fieldValue.length < 4) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.REG_LENGTH,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Phone Number":
        if (!/^\d{10}$/.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.PHONE_NO_VAL,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Email":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.EMAIL_REQ,
          }));
        } else if (!ERROR_MESSAGES.EMAIL_REG.test(fieldValue)) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.VALID_EMAIL_ERROR,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      case "Address":
        if (!fieldValue) {
          setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [fieldLabel]: ERROR_MESSAGES.ADDRESS_REQ,
          }));
        } else {
          setFieldErrors((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
        }
        break;
      default:
        break;
    }
  };
  const clearErrorMessage = (fieldLabel: string) => {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [fieldLabel]: "",
    }));
    setFieldErrors1?.((prevErrors) => ({ ...prevErrors, [fieldLabel]: "" }));
  };
  const handleConfirm = () => {
    validateForm();
    if (!isConfirmDisabled) {
      onConfirm();
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiDialogTitle-root": {
            color: "var(--text-primary, rgba(0, 0, 0, 0.87))",
            fontFamily: "Inter",
            display: "flex",
          },
          "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            backgroundColor: "#fff !important",
            color: "rgba(0, 0, 0, 0.87) !important",
            boxShadow:
              "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
            position: "relative !important",
            overflowY: "auto !important",
            display: "flex !important",
            flexDirection: "column !important",
            maxHeight: "506px !important",
            width: "707px",
            padding: "4px 42px",
          },
          "& .css-uhb5lp": {
            backgroundColor: "#fff !important",
            color: "rgba(0, 0, 0, 0.87) !important",
            boxShadow:
              "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12) !important",
            position: "relative !important",
            overflowY: "auto !important",
            display: "flex !important",
            flexDirection: "column !important",
            maxHeight: "506px !important",
            width: "707px",
            padding: "4px 42px",
          },
        }}
      >
        <DialogTitle
          sx={{
            marginTop: "20px",
            fontFamily: "Inter",
            fontSize: "24px",
            fontWeight: 700,
            color: "#1C1B1F",
            lineHeight: "normal",
            fontStyle: "normal",
          }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: "absolute",
              right: "67px",
              top: "40px",
              width: " 24px",
              height: " 24px",
              padding: "5px",
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={2}>
              {fields.map((field, index) => (
                <Grid
                  item
                  xs={index === 0 || index === 1 || index === 4 ? 12 : 6}
                  key={field.label}
                >
                  <TextField
                    fullWidth
                    label={field.label}
                    variant="outlined"
                    multiline={field.label === "Address"}
                    rows={field.label === "Address" ? 2 : 1}
                    inputProps={{
                      rowsMax: field.type === "textarea" ? 4 : undefined,
                    }}
                    value={(formData as { [key: string]: string })[field.label]}
                    onKeyDown={(e) => field.label === "Phone Number"}
                    error={
                      !!fieldErrors1?.[field.label] ||
                      !!fieldErrors?.[field.label]
                    }
                    helperText={
                      fieldErrors1?.[field.label] || fieldErrors[field.label]
                    }
                    onFocus={() => clearErrorMessage(field.label)}
                    onBlur={() => handleFieldBlur(field.label)}
                    onChange={(e) =>
                      handleFieldChange(field.label, e.target.value)
                    }
                    style={{
                      marginTop: "10px",
                      borderRadius: "4px",
                      fontFamily: "Inter",
                      borderColor: "#01579B",
                    }}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#1A504C",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "green",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#1A504C!important",
                        },
                      },
                      "& .MuiInputBase-input": {
                        color: "black",
                      },
                    }}
                  />
                  {field.label === "Email" && emailError && (
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ paddingLeft: "3px" }}
                    >
                      {emailError}
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <PheonixButton
            onClick={onClose}
            style={{
              backgroundColor: "#D32F2F",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              fontFamily: "Inter",
              marginBottom: "15px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
            }}
          >
            {close}
          </PheonixButton>
          <PheonixButton
            onClick={onConfirm}
            disabled={isButtonDisabled || isConfirmDisabled}
            style={{
              backgroundColor:
                isButtonDisabled || isConfirmDisabled ? "lightgray" : "#165D59",
              fontWeight: 500,
              textTransform: "uppercase",
              fontSize: "14px",
              fontFamily: "Inter",
              marginBottom: "15px",
              marginRight: "15px",
              padding: "6px 16px",
              width: "76px",
              height: "36px",
            }}
          >
            {confirmButtonText}
          </PheonixButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SupplierModal;
