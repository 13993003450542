import React, { useState, useEffect, ChangeEvent } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PRODUCTS, SEARCH_PRODUCTS } from "../graphql/queries";
import PheonixTable from "../components/PheonixTable";
import {
  CssBaseline,
  Box,
  Container,
  useTheme,
  IconButton,
  Drawer,
  CardContent,
  Card,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import PheonixSearchComponent from "../components/PheonixSearchComponent";
import PheonixButton from "../components/PheonixButton";
import styled from "styled-components";
import PheonixProductModal from "../components/PheonixProductModal";
import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
} from "../graphql/mutations";
import PheonixSnackbar from "../components/PheonixSnackbar";
import PheonixSnackbarProps from "../interfaces/PheonixSnackbarProps";
import { ERROR_MESSAGES } from "../constants";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ProductProps from "../interfaces/ProductProps";
import DeleteModal from "../components/DeleteModal";
import PheonixPaper from "../components/PheonixPaper";
const StyledDiv = styled.div`
  padding: 4px 42px 4px 50px;
  @media (max-width: 707px) {
    padding: 4px 42px 4px 20px;
  }
`;
const ProductList: React.FC = ({}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tableData, setTableData] = useState<ProductProps[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [productFormData, setProductFormData] = useState({
    "Product Name and Packing": "",
    "GST in %": "",
    "Retail Margin in %": "",
  });
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: string }>({});
  const [filteredProductsData, setFilteredProductsData] = useState<
    ProductProps[]
  >([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [nameerrorMessage, setNameErrorMessage] = useState("");
  const [addresserrorMessage, setAddressErrorMessage] = useState("");
  const [phonnoerrorMessage, setPhonenoErrorMessage] = useState("");
  const [email, setemail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [customerIdToDelete, setCustomerIdToDelete] = useState<string>("");
  const [iscustomerButtonDisabled, setIscustomerButtonDisabled] =
    useState(true);
  const [addProductMutation] = useMutation(ADD_PRODUCT);
  const [deleteProductMutation] = useMutation(DELETE_PRODUCT);
  const [editproductMutation] = useMutation(EDIT_PRODUCT);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFormEmpty, setIsFormEmpty] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const limit = rowsPerPage;
  const {
    loading: productLoading,
    error: productError,
    data: productData,
    refetch: productRefetch,
  } = useQuery(GET_PRODUCTS, {
    variables: {
      limit,
      offset: (currentPage - 1) * limit,
    },
    onCompleted: (data) => {
      setTableData(data.getAllProducts.products);
      setTableCount(data.getAllProducts.totalCount);
      setTotalPages(Math.ceil(data.getAllProducts.totalCount / limit));
    },
  });

  useEffect(() => {
    if (!productLoading && !productError && productData) {
      setTableData(productData.getAllProducts.products);
      setTableCount(productData.getAllProducts.totalCount);
      setTotalPages(Math.ceil(productData.getAllProducts.totalCount / limit));
    }
  }, [productData, productLoading, productError]);

  useEffect(() => {
    productRefetch();
  }, []);
  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    if (page > currentPage) {
      setCurrentPage(currentPage + 1);
    } else if (page < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  const updateFormEmptyState = () => {
    const isEmpty = Object.values(productFormData).some(
      (value) => value.trim() === ""
    );
    setIsFormEmpty(isEmpty);
  };
  const handleToggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    const filteredData = tableData.filter((order) =>
      Object.values(order).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
    setFilteredProductsData(filteredData);
  };
  const handleOpenModal = () => {
    setIsEditModal(false);
    setProductFormData({
      "Product Name and Packing": "",
      "GST in %": "",
      "Retail Margin in %": "",
    });
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setProductFormData({
      "Product Name and Packing": "",
      "GST in %": "",
      "Retail Margin in %": "",
    });
    setErrorMessage("");
    setAddressErrorMessage("");
    setNameErrorMessage("");
    setIsModalOpen(false);
  };
  const fields = [
    { label: "Product Name and Packing", type: "text" },
    { label: "GST in %", type: "text" },
    { label: "Retail Margin in %", type: "text" },
  ];
  const [editProductId, setEditProductId] = useState<string>("");
  const handleEditClick = (productId: string) => {
    const product = tableData.find((product) => product.id === productId);
    if (product) {
      setEditProductId(productId);
      setProductFormData({
        "Product Name and Packing": product.product_name_packing,
        "GST in %": product.gst,
        "Retail Margin in %": product.retail_margin,
      });
      setIsEditModal(true);
      setIsModalOpen(true);
    }
  };
  const handleConfirm = async () => {
    try {
      let result;
      if (isEditModal) {
        const updateProduct = tableData.find(
          (product) => product.id === editProductId
        );
        if (
          updateProduct &&
          (updateProduct.product_name_packing !==
            productFormData["Product Name and Packing"] ||
            updateProduct.gst !== productFormData["GST in %"] ||
            updateProduct.retail_margin !==
              productFormData["Retail Margin in %"])
        ) {
          result = await editproductMutation({
            variables: {
              id: editProductId,
              product_name_packing: productFormData["Product Name and Packing"],
              gst: productFormData["GST in %"],
              retail_margin: productFormData["Retail Margin in %"]
                ? productFormData["Retail Margin in %"]
                : "0",
            },
          });
          const successMessage = isEditModal
            ? result.data.editProduct.message
            : result.data.createProducts.message;
          if (successMessage) {
            handleCloseModal();
            setSnackbarOpen(true);
            setSnackbarMessage(successMessage);
            productRefetch();
          }
        } else {
          setSnackbarMessage(ERROR_MESSAGES.CUSTOMER_NOT_UPDATED);
          setSnackbarOpen(true);
          setIsEditModal(false);
          setIsModalOpen(false);
          handleCloseModal();
        }
      } else {
        result = await addProductMutation({
          variables: {
            product_name_packing: productFormData["Product Name and Packing"],
            gst: productFormData["GST in %"],
            retail_margin: productFormData["Retail Margin in %"]
              ? productFormData["Retail Margin in %"]
              : "0",
          },
        });
        const successMessage = isEditModal
          ? result.data.editProduct.message
          : result.data.createProducts.message;
        if (successMessage) {
          handleCloseModal();
          setSnackbarOpen(true);
          setSnackbarMessage(successMessage);
          productRefetch();
        }
      }
    } catch (error: any) {
      setNameErrorMessage(error);
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        ["Product Name and Packing"]: error.message,
      }));
    }
  };
  const handleDeleteClick = async (productId: string) => {
    setCustomerIdToDelete(productId);
    setOpenDeleteConfirmation(true);
  };
  const handleDeleteConfirmed = async () => {
    try {
      const result = await deleteProductMutation({
        variables: {
          id: customerIdToDelete,
        },
      });
      productRefetch();
      setSnackbarOpen(true);
      setSnackbarMessage(ERROR_MESSAGES.DELETE_SUCCESSFULL);
    } catch (error) {
      console.error("Error during deletion:", error);
    } finally {
      setOpenDeleteConfirmation(false);
    }
  };
  const columns = [
    { id: "serial_no", label: "S.No" },
    { id: "product_name_packing", label: "Product Name and Packing " },
    { id: "gst", label: "GST" },
    { id: "retail_margin", label: "Retail Margin" },
    { id: "totalQuantity", label: "Quantity" },
    {
      id: "actions",
      label: "Actions",
      align: "center",
      minWidth: 100,
      render: (row: ProductProps) => {
        return (
          <div style={{ flexDirection: "row" }}>
            <IconButton
              onClick={() => handleEditClick(row.id)}
              style={{
                color: "#2E7D32",
                border: "none",
                fontSize: "16px",
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleDeleteClick(row.id)}
              style={{
                fontSize: "16px",
                color: "#D32F2F",
                border: "none",
                marginLeft: 0,
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <div style={{ width: "95%" }}>
      <PheonixSnackbar
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "40px",
        }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        vertical="top"
        horizontal="center"
        contentProps={{
          sx: { backgroundColor: "#1A504C", textAlign: "center" },
        }}
      />
      <StyledDiv>
        <PheonixPaper
          elevation={1}
          marginLeft={-20}
          marginRight={-30}
          height={100}
          marginTop={25}
          style={{
            border: 0,
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            marginBottom: "25px",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            width: isMobile ? "116%" : "104%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PheonixSearchComponent onChange={handleSearch} />
            <div style={{ marginRight: "30px" }}>
              <PheonixButton
                variant="contained"
                onClick={handleOpenModal}
                style={{
                  color: "white",
                  marginTop: "-8px",
                  backgroundColor: "#165D59",
                  fontSize: "16px",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);",
                }}
              >
                {ERROR_MESSAGES.PRODUCT_TITLE}
              </PheonixButton>
            </div>
          </div>
        </PheonixPaper>
        <PheonixTable
          columns={columns}
          data={
            searchQuery && filteredProductsData
              ? filteredProductsData
              : tableData
          }
          totalPages={tableCount}
          currentPage={currentPage}
          rowsPerPage={rowsPerPage}
          handlePageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledDiv>

      <PheonixProductModal
        open={isModalOpen}
        onClose={handleCloseModal}
        title={
          isEditModal ? "Edit Product" : ERROR_MESSAGES.BUTTON_TITLE_Product
        }
        fields={fields}
        onConfirm={handleConfirm}
        confirmButtonText={isEditModal ? "SAVE" : ERROR_MESSAGES.SAVE}
        formData={productFormData}
        setFormData={setProductFormData}
        close={ERROR_MESSAGES.CLOSE}
        isEditModal={isEditModal}
        errorMessage={errorMessage}
        phonenoErrorMessage={phonnoerrorMessage}
        setErrorMessage={setErrorMessage}
        setPhonenoErrorMessage={setPhonenoErrorMessage}
        nameerrorMessage={nameerrorMessage}
        setNameErrorMessage={setNameErrorMessage}
        addresserrorMessage={addresserrorMessage}
        setAddressErrorMessage={setAddressErrorMessage}
        fieldErrors1={fieldErrors}
        setFieldErrors1={setFieldErrors}
        disabled={!iscustomerButtonDisabled}
        isFormEmpty={isFormEmpty}
        updateFormEmptyState={updateFormEmptyState}
      />
      <DeleteModal
        open={openDeleteConfirmation}
        onClose={() => setOpenDeleteConfirmation(false)}
        onConfirm={handleDeleteConfirmed}
        entity={ERROR_MESSAGES.PRODUCT_NAME}
      />
    </div>
  );
};
export default ProductList;
