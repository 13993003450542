import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PheonixDashboardTable from "../../components/PheonixDashboardTable";
import InvoiceProps from "../../interfaces/InvoiceProps";
import { GET_SALES } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { useNavigate, useLocation } from "react-router-dom";
import { ERROR_MESSAGES } from "../../constants";
interface SalesByProductProps {}
const SalesByProduct: React.FC<SalesByProductProps> = () => {
  const [filteredData, setFilteredData] = useState<InvoiceProps[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [tableData, setTableData] = useState<InvoiceProps[]>([]);
  const [tableCount, setTableCount] = useState<number>(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = () => {
    navigate("/invoice");
  };

  const [customerFormData, setCustomerFormData] = useState({
    "Invoice No": "",
    Customer: "",
    InvoiceDate: "",
  });
  const limit = rowsPerPage;
  const { loading, error, data, refetch } = useQuery(GET_SALES, {
    variables: {
      limit,
      offset: (currentPage - 1) * limit,
    },
    onCompleted: (data) => {
      setTableData(data.getAllSales.sales);
      setTableCount(data.getAllSales.totalCount);
      setTotalPages(Math.ceil(data.getAllSales.totalCount / limit));
    },
  });
  useEffect(() => {
    if (!loading && !error && data) {
      setTableData(data.getAllSales.sales);
      setTableCount(data.getAllSales.totalCount);
      setTotalPages(Math.ceil(data.getAllSales.totalCount / limit));
    }
  }, [data, loading, error]);
  const columns = [
    { id: "invoice_no", label: "Invoice No" },
    { id: "customer_name", label: "Customer " },
    { id: "invoice_date", label: "Invoice Date" },
  ];

  return (
    <Card
      style={{
        width: "376px",
        height: "282px",
        marginTop: "5px",
        borderRadius: "10px",
        border: "1px solid #0000001F",
        boxShadow: "0 2px 4px rgba(0,0,0,0.7)",
      }}
    >
      <CardContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            variant="subtitle1"
            style={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "700",
              textAlign: "left",
              fontStyle: "bold",
              marginTop: "0",
            }}
          >
            {ERROR_MESSAGES.SALES_TXT}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              textAlign: "right",
              marginRight: "20px",
              color: "rgba(33, 150, 243, 1)",
              cursor: "pointer",
              fontWeight: "400",
            }}
            onClick={handleClick}
          >
            {ERROR_MESSAGES.VIEW_TXT}
          </Typography>
        </div>
        <div style={{ width: "110%", marginTop: "0%", marginLeft: "2px" }}>
          <PheonixDashboardTable
            columns={columns}
            data={filteredData.length > 0 ? filteredData : tableData}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default SalesByProduct;
