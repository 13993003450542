import React, { ChangeEvent, useEffect, useState } from "react";
import PheonixInvoiceTableProps from "../interfaces/PheonixInvoiceTableProps";
import { useQuery, useMutation } from "@apollo/client";
import { GET_STOCKS, GET_ALL_STOCKS } from "../graphql/queries";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Button,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import StockProps from "../interfaces/StockProps";
import { RowData } from "../interfaces/PheonixInvoiceTableProps";
import { getValue } from "@mui/system";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { ERROR_MESSAGES } from "../constants";
interface Stock {
  id: string;
  product_name: string;
  expiry_date: string;
}

const PheonixInvoiceTable: React.FC<PheonixInvoiceTableProps> = ({
  columns,
  rows,
  handleInputChange,
  handleInvoiceTableChange,
  quantityErrors,
  handleNetAmountChange,
  handleAddRow,
  editDiscount,
  editCgst,
  editIgst,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [productNames, setProductNames] = useState<
    { id: string; name: string; date: string }[]
  >([]);
  const [stocksData, setStocksData] = useState<StockProps[]>([]);
  const [selectedStocksData, setSelectedStocksData] = useState<StockProps[]>(
    []
  );
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [mappedData, setMappedData] = useState<RowData | null>(null);
  const [isSGSTChecked, setIsSGSTChecked] = useState(true);
  const [isCGSTChecked, setIsCGSTChecked] = useState(true);
  const [isIGSTChecked, setIsIGSTChecked] = useState(false);
  const [discount, setDiscount] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const limit = rowsPerPage;
  const [isAnyCheckboxChecked, setIsAnyCheckboxChecked] = useState(true);
  const { loading, error, data, refetch } = useQuery(GET_ALL_STOCKS, {
    onCompleted: (data) => {
      setProductNames(
        data.getStockList.map((stock: Stock) => ({
          id: stock.id,
          name: stock.product_name,
          date: stock.expiry_date,
        }))
      );
      setStocksData(data.getStockList);
    },
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setProductNames(
        data.getStockList.map((stock: Stock) => ({
          id: stock.id,
          name: stock.product_name,
          date: stock.expiry_date,
        }))
      );
      setStocksData(data.getStockList);
    }
  }, [data, loading, error]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (editDiscount) {
      setDiscount(editDiscount);
    }

    if (editIgst && editIgst !== null && editIgst > 0 && !isIGSTChecked) {
      setIsIGSTChecked(true);
      setIsCGSTChecked(false);
    } else if (editIgst === null && isIGSTChecked) {
      setIsIGSTChecked(false);
      setIsCGSTChecked(true);
    }
  }, [editDiscount, editIgst]);

  const totalQuantity = rows.reduce((sum, row) => {
    const quantity = parseInt(row["Quantity"]) || 0;
    return sum + quantity;
  }, 0);

  const totalGSTAmount =
    parseFloat(
      rows
        .filter(
          (row) =>
            row["Quantity"] &&
            row["PTR"] &&
            row["GST"] &&
            parseFloat(row["Quantity"]) > 0 &&
            parseFloat(row["PTR"]) > 0
        )
        .reduce((sum, row) => {
          const quantity = parseFloat(row["Quantity"]) || 0;
          const ptr = parseFloat(row["PTR"]) || 0;
          const gst = parseFloat(row["GST"]) || 0;
          const amount = quantity * ptr;

          if (isCGSTChecked || isSGSTChecked) {
            const gstAmount = (gst / 100) * amount;
            return sum + gstAmount / 2;
          } else {
            return sum;
          }
        }, 0)
        .toFixed(2)
    ) || 0;

  const totalTax =
    parseFloat(
      rows
        .filter(
          (row) =>
            row["Quantity"] &&
            row["PTR"] &&
            row["GST"] &&
            parseFloat(row["Quantity"]) > 0 &&
            parseFloat(row["PTR"]) > 0
        )
        .reduce((sum, row) => {
          const quantity = parseFloat(row["Quantity"]) || 0;
          const ptr = parseFloat(row["PTR"]) || 0;
          const gst = parseFloat(row["GST"]) || 0;
          const amount = quantity * ptr;
          const gstAmount = (gst / 100) * amount;
          return sum + gstAmount;
        }, 0)
        .toFixed(2)
    ) || null;

  const totalIGST =
    parseFloat(
      rows
        .filter(
          (row) =>
            row["Quantity"] &&
            row["PTR"] &&
            row["GST"] &&
            parseFloat(row["Quantity"]) > 0 &&
            parseFloat(row["PTR"]) > 0
        )
        .reduce((sum, row) => {
          const quantity = parseFloat(row["Quantity"]) || 0;
          const ptr = parseFloat(row["PTR"]) || 0;
          const gst = parseFloat(row["GST"]) || 0;
          const amount = quantity * ptr;
          const gstAmount = (gst / 100) * amount;

          return sum + (isIGSTChecked ? gstAmount : 0);
        }, 0)
        .toFixed(2)
    ) || null;

  const totalAmount =
    parseFloat(
      rows
        .filter(
          (row) =>
            row["Quantity"] &&
            row["PTR"] &&
            parseFloat(row["Quantity"]) > 0 &&
            parseFloat(row["PTR"]) > 0
        )
        .reduce((sum, row) => {
          const quantity = parseFloat(row["Quantity"]) || 0;
          const ptr = parseFloat(row["PTR"]) || 0;
          const amount = quantity * ptr;
          return sum + amount;
        }, 0)
        .toFixed(2)
    ) || null;

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const discountValue = event.target.value;
    if (!discountValue) {
      setErrorMessage("");
      setDiscount(null);
      return;
    }
    const discount = parseFloat(discountValue);

    if (!isNaN(discount) && discount >= 0 && discount <= 100) {
      setDiscount(discount);
    } else {
      setDiscount(0);
    }
  };

  const newNetAmount =
    totalAmount !== null && totalTax !== null
      ? discount
        ? totalAmount + totalTax - (totalAmount + totalTax) * (discount / 100)
        : totalAmount + totalTax
      : null;

  const netAmount =
    newNetAmount !== null ? parseFloat(newNetAmount.toFixed(2)) : null;

  const discountAmount =
    totalAmount !== null && totalTax !== null && discount !== null
      ? ((totalAmount + totalTax) * (discount / 100)).toFixed(2)
      : null;

  useEffect(() => {
    if (handleNetAmountChange) {
      let cgstAmount = 0;
      let igstAmount = 0;

      if (isCGSTChecked) {
        cgstAmount = totalGSTAmount ? totalGSTAmount : 0;
      }
      if (isIGSTChecked) {
        igstAmount = totalIGST ? totalIGST : 0;
      }
      handleNetAmountChange(
        netAmount,
        cgstAmount,
        rows.length,
        totalQuantity,
        totalAmount,
        totalTax,
        igstAmount,
        discount
      );
    }
  }, [
    netAmount,
    handleNetAmountChange,
    isCGSTChecked,
    isIGSTChecked,
    discount,
  ]);

  const handleCGSTChange = () => {
    if (!isCGSTChecked && !isIGSTChecked) {
      setIsCGSTChecked(true);
    } else if (isIGSTChecked) {
      setIsIGSTChecked(false);
      setIsCGSTChecked(true);
    }
  };

  const handleIGSTChange = () => {
    if (!isCGSTChecked && !isIGSTChecked) {
      setIsIGSTChecked(true);
    } else if (isCGSTChecked) {
      setIsCGSTChecked(false);
      setIsIGSTChecked(true);
    }
  };

  const mapStockToRowData = (stock: StockProps, rowIndex: number): RowData => {
    const {
      id,
      product_name,
      batch_no,
      mrp,
      ptramt,
      gst,
      expiry_date,
      quantity,
    } = stock;

    return {
      product_id: id,
      ProductName: product_name,
      BatchNumber: batch_no,
      MRP: mrp,
      PTR: ptramt,
      ExpiryDate: expiry_date,
      GST: gst,
      available: quantity,
    };
  };

  const handleProductChange = (
    event: React.ChangeEvent<{}>,
    newValue: any,
    rowIndex: number
  ) => {
    setSelectedProduct(newValue);
    if (newValue) {
      const selectedStock: StockProps | undefined = stocksData.find(
        (stock) => stock.id === newValue.id
      );
      if (selectedStock) {
        const mappedRowData: RowData = mapStockToRowData(
          selectedStock,
          rowIndex
        );
        setMappedData(mappedRowData);
        handleInvoiceTableChange(mappedRowData, rowIndex);
      }
    }
  };

  const cellStyles = {
    border: "1px solid lightgrey",
    padding: "10px",
    width: "20%",
    color: "black",
    fontWeight: 700,
    fontFamily: "Inter",
  };
  const cellStyles1 = {
    border: "1px solid lightgrey",
    padding: "10px",
    width: "18%",
    color: "black",
    fontWeight: 500,
    fontFamily: "Inter",
  };

  return (
    <TableContainer style={{ marginTop: "24px", width: "960px" }}>
      <Table>
        <div>
          <TableRow
            style={{ border: "1px solid lightgrey", fontFamily: "inter" }}
          >
            {columns.map((column) => (
              <TableCell
                key={column.field}
                style={{
                  width: column.width,
                  padding: column.padding || "0px",
                  fontSize: "14px",
                  color: "black",
                  fontFamily: "inter",
                  fontWeight: 600,
                  font: "Inter",
                  marginLeft: "5%",
                  textAlign:
                    column.field === "GST" || column.field === "Amount"
                      ? "center"
                      : "left",
                }}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </div>

        <div>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                style={{
                  border: "1px solid lightgrey",
                  color: "black",
                  textAlign: "center",
                  font: "Inter",
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    style={{
                      width: column.width,
                      fontFamily: "Inter",
                      border: "1px solid lightgrey",
                      color: "black",
                      font: "Inter",
                      textAlign:
                        column.field === "GST" || column.field === "BatchNumber"
                          ? "left"
                          : "right",
                    }}
                  >
                    {column.render ? (
                      column.render(index)
                    ) : column.field === "ProductName" ? (
                      <Autocomplete
                        id="combo-box-demo"
                        options={productNames}
                        disableClearable={true}
                        getOptionLabel={(option) =>
                          option.date
                            ? `${option.name} - ${option.date}`
                            : option.name
                        }
                        onChange={(event, newValue) =>
                          handleProductChange(event, newValue, index)
                        }
                        value={
                          productNames.find(
                            (option) => option.name === row.ProductName
                          ) || { id: "", name: "", date: "" }
                        }
                        renderInput={(params) => (
                          <TextField {...params} label="Product" />
                        )}
                        sx={{
                          "& label.Mui-focused": {
                            color: "#1A504C",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "green",
                          },
                          "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                              borderColor: "#1A504C!important",
                            },
                          },
                        }}
                      />
                    ) : column.field === "Quantity" ? (
                      <div>
                        <TextField
                          type="number"
                          value={row[column.field] || ""}
                          size="small"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputChange(
                              index,
                              column.field,
                              e.target.value,
                              row.available
                            )
                          }
                          helperText={
                            quantityErrors?.[index]
                              ? quantityErrors[index]
                              : row.ProductName &&
                                row.available !== undefined &&
                                `Available:${row.available}`
                          }
                          error={!!quantityErrors?.[index]}
                          style={{
                            width: "100%",
                            padding: "4px",
                            height: "10px",
                            marginBottom: "45px",
                            color: "black",
                          }}
                          sx={{
                            "& label.Mui-focused": {
                              color: "#1A504C",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "green",
                            },
                            "& .MuiOutlinedInput-root": {
                              "&.Mui-focused fieldset": {
                                borderColor: "#1A504C!important",
                              },
                            },
                          }}
                        />
                      </div>
                    ) : (
                      <div>{row[column.field]}</div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <Button
            onClick={handleAddRow}
            style={{
              backgroundColor: "#165D59",
              color: "white",
              fontSize: "14px",
              fontFamily: "Inter",
              margin: "15px",
              marginLeft: "2px",
            }}
          >
            {ERROR_MESSAGES.ADD_ITEM}
          </Button>
        </div>

        <TableContainer>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "50%",
              marginLeft: "50%",
              marginTop: "0%",
            }}
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={1} style={cellStyles}>
                    {ERROR_MESSAGES.ITEM_TEXT}
                  </TableCell>
                  <TableCell colSpan={1} style={cellStyles1}>
                    {netAmount && rows.length}
                  </TableCell>
                  <TableCell colSpan={1} style={cellStyles}>
                    {ERROR_MESSAGES.QUANTITY_TEXT}
                  </TableCell>
                  <TableCell colSpan={1} style={cellStyles1}>
                    {totalQuantity}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={1} style={cellStyles}>
                    {ERROR_MESSAGES.AMOUNT_TEXT}
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    style={{ ...cellStyles1, fontWeight: "700" }}
                  >
                    {totalAmount}
                  </TableCell>
                  <TableCell colSpan={1} style={cellStyles}>
                    {ERROR_MESSAGES.TAX}
                  </TableCell>
                  <TableCell colSpan={1} style={cellStyles1}>
                    {totalTax}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Table>

      <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Paper
            elevation={3}
            sx={{ width: "80%", height: "115px" }}
            style={{ marginLeft: "1%", margin: "5px", marginTop: "8%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "5px",
                width: "140%",
              }}
            >
              <div
                style={{
                  flex: 1,
                  fontSize: "16px",
                  font: "Inter",
                  fontWeight: 700,
                  color: "black",
                  marginLeft: "2%",
                }}
              >
                {ERROR_MESSAGES.TAX_TEXT}:
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isCGSTChecked}
                      onChange={handleCGSTChange}
                      color="success"
                      style={{ marginLeft: "10px" }}
                    />
                  }
                  label="CGST + SGST"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isIGSTChecked}
                      onChange={handleIGSTChange}
                      color="success"
                    />
                  }
                  label={<span style={{ fontFamily: "inter" }}>IGST</span>}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "70%",
                fontFamily: "inter",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginTop: "15px",
                  marginRight: "10px",
                  color: "black",
                  font: "Inter",
                  fontWeight: 700,
                  marginLeft: "5%",
                }}
              >
                {ERROR_MESSAGES.DISCOUNT}:
              </div>
              <TextField
                id="outlined-error"
                variant="outlined"
                label="Discount"
                type="number"
                size="small"
                inputProps={{ style: { border: "none" } }}
                onChange={handleSelect}
                value={discount}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                style={{
                  width: "45%",
                  height: "5px",
                  marginTop: "8px",
                }}
                sx={{
                  "& label.Mui-focused": {
                    color: "#1A504C",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "green",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: "#1A504C!important",
                    },
                  },
                }}
              />
            </div>
          </Paper>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            flex: 1,
            marginTop: "-15%",
          }}
        >
          <Table
            aria-label="spanning table"
            style={{ border: "red", marginTop: "0px" }}
          >
            <TableBody style={{ border: "none" }}>
              {isCGSTChecked ? (
                <>
                  <TableRow>
                    <TableCell colSpan={1} style={cellStyles}>
                      {ERROR_MESSAGES.CGST}
                    </TableCell>
                    <TableCell colSpan={1} style={cellStyles1}>
                      {totalGSTAmount}
                    </TableCell>
                    <TableCell colSpan={1} style={cellStyles}>
                      {ERROR_MESSAGES.SGST}
                    </TableCell>
                    <TableCell colSpan={1} style={cellStyles1}>
                      {totalGSTAmount}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={2} style={cellStyles}>
                    {ERROR_MESSAGES.IGST}
                  </TableCell>
                  <TableCell colSpan={2} style={cellStyles1}>
                    {totalIGST}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={2} style={cellStyles}>
                  {ERROR_MESSAGES.DISCOUNT}
                </TableCell>
                <TableCell colSpan={2} style={cellStyles1}>
                  {discountAmount}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={cellStyles}>
                  {ERROR_MESSAGES.NET_AMOUNT}
                </TableCell>
                <TableCell
                  colSpan={2}
                  style={{ ...cellStyles1, fontWeight: "700" }}
                >
                  {netAmount}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </TableContainer>
  );
};

export default PheonixInvoiceTable;
